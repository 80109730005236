/* BotImage.css */

@keyframes typing {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  
  .typing-animation {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #333;
    border-radius: 50%;
    margin-right: 4px;
    animation: typing 1s infinite;
  }
  
  /* Add more styles as needed */
  