/* Main */
body {
  background-color: #030B15;
  font-family: "Poppins", sans-serif !important;
  color: #fff;
}

.spacercontainer {
  margin-top: 50px;
}

.dropdown-background {
  background-color: #030B15;
}

.main-content {
  background-color: #030B15;
  background-image: url("../uploads/background_main.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}


.dropdown-background a {
   border-bottom: 1px solid white;
}
.selected-box {
  box-shadow: 0 8px 10px rgba(18, 208, 255, 1);
  border: rgba(98, 191, 186, 1);
}

.unselected-box {
  box-shadow: 0 3px 8px rgba(18, 208, 255, 0.5);
  border: rgba(98, 191, 186, 0.5);
  /* backdrop-filter: blur(6.7px);
  -webkit-backdrop-filter: blur(6.7px); */
}

.multiplier-box:hover{
  box-shadow: 0 8px 10px rgba(18, 208, 255, 1);
  border: rgba(98, 191, 186, 1);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1;
}

.multiplier-box {
  position: relative;
}

.multiplier-box:hover .overlay {
  opacity: 0;
}

.hilighted-card-box{
  border-radius: 15px;
  box-shadow: 0 3px 8px rgba(18, 208, 255, 1); /* Shadow effect */
}

.dropdown-background a
{
    border-bottom: 1px solid white;

}

.notification-container {
  background:rgba(153, 153, 153, 0.1)
}


.form-no-background {
  background: transparent !important;
}
.c1-pending-background
{
  background-color: rgb(249 115 22);
}

.form-no-background{
    background: transparent !important;
    border: 2px solid #00c4f4;

}

select.form-control:focus {
  color: #ffffff;
  background: #030B15 !important;
}


.form-no-background option{
  background: transparent !important;
  border: 2px solid #00c4f4;
}

.primary-border{
  border: 2px solid #00c4f4;
}

.primary-color{
  color: #00c4f4;
}

.hilighted-box{
  background: rgba(145, 134, 134, 0);
  border-radius: 15px;
  padding: 25px;
  box-shadow: 0 3px 8px rgba(18, 208, 255, 1); /* Shadow effect */
  backdrop-filter: blur(6.7px);
  -webkit-backdrop-filter: blur(6.7px);
}

.primary-br-1{
  border-right: 1px solid #00c4f4;
}

.primary-br-2{
  border-right: 2px solid #00c4f4;
}

.primary-bb-2{
  border-bottom: 2px solid #00c4f4;
}

.primary-bl-1{
  border-left: 1px solid #00c4f4;
}

.primary-bb-1{
  border-bottom: 1px solid #00c4f4;
}

.primary-bl-2{
  border-left: 2px solid #00c4f4;
}

.primary-bg{
  background-color: #030B15;
}

.secondary-bg{
  background-color: #00c4f4;
}

.primary-color{
  color: #00c4f4;
}

.navbar-menu-c1{
  margin-left: 0 !important;
}

.allNotificationsContainer{
  max-height: 400px;
  overflow-y: scroll;
}

.c1-navbar .relative{
  margin-left: 0 !important;
}

.primary-color-glow{
  color: rgba(0, 196, 244,1);
  text-shadow: 0 0 10px #fff, 0 0 15px  #00c4f4, 0 0 20px #00c4f4, 0 0 25px;
}
  

.primary-color-glow:hover{
  color: rgba(0, 196, 244, 1);
}

.c1-primary-hover:hover{
  border: 3px solid #12d0ff;
}

.c1-pending-background{
  background-color: #f97316;
}


.c1-primary-menu-hover:hover{
  border: 3px solid #12d0ff;
  color: rgba(0, 196, 244, 1);
}

/* Header */
.c1-header-logo{
  max-width: 55px;;
}

/* Footer */


/* Login */
.website-main-bg{
  background-color: #030B15;
}

/* Buttons */

/* .c1-primary-btn{
    user-select: none;
    -moz-user-select: none;
    background: #040E18;
    border-radius: 70px;
    border: 2px solid transparent;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: .5px;
    line-height: 1;
    margin-bottom: 0;
    padding: 25px 50px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: all 0.4s 0s;
    vertical-align: middle;
    white-space: nowrap;
    background: linear-gradient(90deg, rgba(0, 196, 244, 1) 0%, rgba(4, 12, 21, 1) 49%, rgba(0, 196, 244, 1) 100%);
    background-clip: padding-box;
    position: relative;
  
} */

/* .c1-primary-btn::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: -6px;
    background: linear-gradient(90deg, rgba(0, 196, 244, 1) 0%, rgba(4, 12, 21, 1) 49%, rgba(0, 196, 244, 1) 100%);
    border-radius: 70px;
    transition: all 0.4s ease 0s;
    box-sizing: border-box;
    z-index: 1;
} */

.select-transparent {
  /* Make select background transparent */
  color: black; /* Change text color to make it visible against a potentially light background */
}

ol li {
  list-style: decimal;
  list-style-position: inside;
  color: white;
}

.c1-primary-btn{
  user-select: none;
  -moz-user-select: none;
  background: #040E18;
  border-radius: 70px;
  border: 2px solid #00c4f4;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .5px;
  line-height: 1;
  margin-bottom: 0;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  background-clip: padding-box;
  position: relative;
  
}

.c1-primary-btn-sm{
  user-select: none;
  -moz-user-select: none;
  background: #040E18;
  border-radius: 70px;
  border: 2px solid #00c4f4;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .3px;
  line-height: 1;
  margin-bottom: 0;
  padding: 5px 25px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  background-clip: padding-box;
  position: relative;
  
}

.c1-primary-radio{
  user-select: none;
  -moz-user-select: none;
  background: #040E18;
  border-radius: 70px;
  border: 2px solid #2282C1;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .5px;
  line-height: 1;
  margin-bottom: 0;
  padding: 10px 25px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  background-clip: padding-box;
  position: relative;
}

.c1-primary-btn-active{
  background: #2282C1;
  color: #000;
}

.grid.dashboard-grid-container{
  display: grid;
  grid-gap: 16px;
  padding: 16px;
  grid-template-columns: repeat(auto-fill,minmax(160px,1fr));
  grid-auto-flow: column;
  grid-auto-columns: minmax(160px,1fr);
  overflow-x: auto;
}

.c1-primary-btn:hover{
  border: 3px solid #12d0ff;
}

/* Icons */

/* --brands-- */
.facebook{
  background: #4267B2;
  border: 2px solid #4267B2;
}

.twitter{
  background: #1DA1F2;
  border: 2px solid #1DA1F2;
}

.google{
  background: #DB4437;
  border: 2px solid #DB4437;
}

.facebook:hover{
  border: 2px solid #12d0ff;
  background: #040E18;
  /* background: linear-gradient(90deg, rgba(0, 196, 244, 1) 0%, rgba(4, 12, 21, 1) 49%, rgba(0, 196, 244, 1) 100%); */
}

.twitter:hover{
  border: 2px solid #12d0ff;
  background: #040E18;
  /* background: linear-gradient(90deg, rgba(0, 196, 244, 1) 0%, rgba(4, 12, 21, 1) 49%, rgba(0, 196, 244, 1) 100%); */
}

.google:hover{
  border: 2px solid #12d0ff;
  background: #040E18;
  /* background: linear-gradient(90deg, rgba(0, 196, 244, 1) 0%, rgba(4, 12, 21, 1) 49%, rgba(0, 196, 244, 1) 100%); */
}

/* Dashboard */

.single-product-container{
  padding: 35px;
}

.product-icons{
   margin: 0;
}

#notification{
  position: relative;
}

#notification .badge{
  position: absolute;
  top: -10px;
  right: -10px;
  /* padding: 2px 4px; */
  padding: 1px 6px;
  border-radius: 50%;
  height: 25px;
  background: red;
  color: white;
}


.dz-message {
  text-align: center;
  padding: 100px;
}

/* Single product table */
.singleProductTable-tr:hover {
  background-color: rgba(18, 208, 255, 1); /* Color to apply on hover */
}


  
th,
td {
  margin: 0;
  padding: 0.5rem;
  
}
  
th:last-child {
  border-right: 0;
}
  
td:last-child {
  border-right: 0;
}

.c1-closebtn{
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.c1-closebtn:hover {
  color: black;
}

.c1-alert {
  padding: 20px;
  background-color: rgba(247, 47, 32, 0.7);
  color: white;
}

.menu-container {
  display: flex;
  flex-wrap: wrap;
}
  
.menu-item {
  display: flex; /* Change to flex to allow for inline positioning */
  align-items: center; /* Align items vertically in the center */
  text-align: center; 
  padding: 10px;
}

.menu-item svg {
  margin-right: 5px; /* Add some space between the icon and the text */
}

.menu-item:hover {
  color: #12d0ff;
}

.menu-item-selected {
  color: #12d0ff;
}

.primary-c1-text{
  color: #12d0ff;
}

.dropzone{
  background: rgba(255, 255, 255, 0.2);
}



@media only screen and (max-width: 600px) {
  .c1-full-height-mobile {
    height: 100% !important;
  }

  .dz-message{
      padding: unset !important;
  }
}

@media (max-width: 768px) {
  /* Adjust the modal width for smaller screens */
  .external_deposit_modal {
    max-width: 90%;
  }
}

.custom-scrollbar ::-webkit-scrollbar {
  width: 12px;
}
  
.custom-scrollbar ::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 6px;
}
  
.custom-scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
  
.custom-scrollbar ::-webkit-scrollbar-track {
  background: transparent;
}



.currency-display-btn {
  background-color: #fff; /* Adjust background color as needed */
  border: none;
  cursor: pointer;
  padding: 10px;
  min-width: 150px; /* Adjust as needed to fit content */
}
  
.currency-display {
  margin: 0; /* Remove default margin */
}
  
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9; /* Adjust dropdown background as needed */
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}
  
.currency-display-btn:hover .dropdown-content {
  display: block;
  transition: opacity 0.5s ease, visibility 0.5s;
}
  
.dropdown-content p {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.loader-c1fs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Center vertically on the page */
}

.loader-c1fs {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  color: #fff;
}

.loader-c1fs:before,
.loader-c1fs:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: spin 2s linear infinite;
}

.loader-c1fs:after {
  color: #6ECAFF;
  transform: rotateY(70deg);
  animation-delay: .4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}











/* New improved */

.authentication-container {
  background: rgba(145, 134, 134, 0);
  border-radius: 15px;
  padding: 25px;
  box-shadow: 0 3px 8px rgba(18, 208, 255, 1); /* Shadow effect */
  max-width: 350px; /* Limit the width of the login container */
  width: 100%; /* Ensure the container fills its parent */
  backdrop-filter: blur(6.7px);
  -webkit-backdrop-filter: blur(6.7px);
  position: fixed; /* Position the container */
  top: 50%; /* Move the container 50% from the top */
  left: 50%; /* Move the container 50% from the left */
  transform: translate(-50%, -50%); /* Center the container */
}

.single-product-user-info,
.single-product-user-table {
  background: rgba(145, 134, 134, 0);
  border-radius: 15px;
  padding: 25px;
  box-shadow: 0 3px 8px rgba(18, 208, 255, 1); /* Shadow effect */
  backdrop-filter: blur(6.7px);
  -webkit-backdrop-filter: blur(6.7px);
}

.singleProductTable-head {
  color:rgba(0, 196, 244, 1);
}




/* Registration */
    .authentication-registration-container {
      background: rgba(145, 134, 134, 0);
      border-radius: 15px;
      padding: 25px;
      box-shadow: 0 3px 8px rgba(18, 208, 255, 1); /* Shadow effect */
      width: 100%; /* Ensure the container fills its parent */
      backdrop-filter: blur(6.7px);
      -webkit-backdrop-filter: blur(6.7px);
      margin: 25px 0;
    }
/* Registration END */

.authentication-word-highlight {
  color: rgba(18, 208, 255, 1);
}

.authentication-word-font-size-13 {
  font-size: 13px;
}

.authentication-bottom-border {
  border-bottom: 1px solid rgba(18, 208, 255, 1);
}

.authentication-top-border {
  border-top: 1px solid rgba(18, 208, 255, 1);
}

.authentication-left-border {
  border-left: 1px solid rgba(18, 208, 255, 1);
}

.authentication-right-border {
  border-right: 1px solid rgba(18, 208, 255, 1);
}

.authentication-highlight-color {
  color: rgba(18, 208, 255, 1);
}

.authentication-highlight-color-hover:hover {
  color: rgba(34, 130, 193, 1); /* Change color to blue on hover */
}

/* LR container img */
.authentication-container-img {
  max-width: 100%; /* Ensure the image does not exceed the width of its container */
  max-height: 90px;
  display: block; 
  margin: 0px auto 0; 
  position: relative;
}

/* Login form submit button */
.authentication-button {
  border: none;
  background-image: linear-gradient(30deg, rgba(34, 130, 193, 1), rgba(18, 208, 255, 1));
  border-radius: 20px;
  background-size: 100% auto;
  font-family: inherit;
  font-size: 17px;
  padding: 0.6em 1.5em;
}

/* Hover state for login form submit button */
.authentication-button:hover {
  background-position: right center;
  background-size: 200% auto;
  -webkit-animation: pulse 2s infinite;
  animation: pulse512 3s infinite;
}

input[type="radio"] {
  display: none;
}


@keyframes pulse512 {
  0% {
      box-shadow: 0 0 0 0 #05bada66;
  }

  70% {
      box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
  }

  100% {
      box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
  }
}


/* END New Authentication Upgrade styling */

/* Loans */
.loan-info-btn{
  border: 2px solid #00c4f4;
  width: fit-content;
  padding: 10px;
}

/* CustomStyles.css */
#search-bar-0{
  color: white !important; /* Sets text color */
   background-color: transparent  !important; /* Sets background to transparent */
   border: 1px solid #00c4f4  !important; /* Optional: adds a white border */
   border-radius: 5px;
   padding: 5px;
   margin-top: 5px;
 }

 .theme-input-border{
  border: 1px solid #00c4f4;
  border-radius: 5px;
  padding: 5px;
 }

 .loanCalculatorContainer select{
  color: white !important; /* Sets text color */
  background-color: transparent  !important; /* Sets background to transparent */
  border: 1px solid #00c4f4  !important; /* Optional: adds a white border */
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
 }

 .loanCalculatorContainer select option::before{
  color: white !important; /* Sets text color */
  background-color: transparent  !important; /* Sets background to transparent */
 }

 .loanCalculatorContainer .justify-content-md-end.justify-content-center.align-items-center.row input{
  color: white !important; /* Sets text color */
  background-color: transparent  !important; /* Sets background to transparent */
  border: 1px solid #00c4f4  !important; /* Optional: adds a white border */
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
 }

 .dashboard-popup{
  font-size: 1rem;
 }

 @media only screen and (max-width: 600px) {
  .dashboard-popup {
    font-size: 0.75rem;
  }

}